import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser} from "@api-platform/admin";
import React from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  Tab,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  TabbedShowLayout,
  usePermissions
} from "react-admin";
import AddParameterButton from "./addParameterButton";
import {RefreshableListBySelectedProject} from "../RefreshableListBySelectedProject";

export const AiToolList = props => (
  <RefreshableListBySelectedProject {...props}>
    <FieldGuesser source={"name"}/>
    <FieldGuesser source={"displayType"}/>
    <FieldGuesser source={"isFindProducts"}/>
    <FieldGuesser source={"isGetQuizQuestion"}/>
    <FieldGuesser source={"isValidateQuizAnswer"}/>
    <FieldGuesser source="isAiTextCompletion"/>
    <FieldGuesser source={"endSession"}/>
    <FieldGuesser source={"isMoveToAgent"}/>
    <FieldGuesser source={"isFetchContent"}/>
    <FieldGuesser source={"contentType"}/>
    <FieldGuesser source={"showyAiConfig"}/>
    <FieldGuesser source={"aiAssistant"}/>
  </RefreshableListBySelectedProject>
);

export const AiToolCreate = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <CreateGuesser {...props}>
      <InputGuesser source={"name"} validate={[required()]}/>
      <InputGuesser source={"description"}/>
      <InputGuesser source={"displayType"}/>
      <InputGuesser source={"isFindProducts"}/>
      <InputGuesser source={"isGetQuizQuestion"}/>
      <InputGuesser source={"isValidateQuizAnswer"}/>
      <InputGuesser source="isAiTextCompletion"/>
      <InputGuesser
        source="aiTextCompletionPrompt"
        multiline
        fullWidth
      />
      <InputGuesser source={"endSession"}/>
      <InputGuesser source={"isMoveToAgent"}/>
      <InputGuesser source={"isFetchContent"}/>
      <InputGuesser source={"contentType"}/>
      <ReferenceInput
        allowEmpty={true}
        source="showyAiConfig"
        reference="showy_ai_configs"
        label="Showy Ai Config"
        filterToQuery={searchText => ({name: searchText})}
        // validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <ReferenceInput
        allowEmpty={true}
        source="aiAssistant"
        reference="ai_assistants"
        label="AI Assistant"
        filterToQuery={searchText => ({name: searchText})}
        // validate={[required()]}
      >
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </CreateGuesser>
  )
};

export const AiToolEdit = props => {
  const {loaded, permissions} = usePermissions();
  return (
    <EditGuesser {...props}>
      <TabbedShowLayout fullWidth={true}>
        <Tab label="Summary">
          <InputGuesser source={"name"} validate={[required()]}/>
          <InputGuesser source={"description"}/>
          <InputGuesser source={"displayType"}/>
          <InputGuesser source={"isFindProducts"}/>
          <InputGuesser source={"isGetQuizQuestion"}/>
          <InputGuesser source={"isValidateQuizAnswer"}/>
          <InputGuesser source="isAiTextCompletion"/>
          <InputGuesser
            source="aiTextCompletionPrompt"
            multiline
            fullWidth
          />
          <InputGuesser source={"endSession"}/>
          <InputGuesser source={"isMoveToAgent"}/>
          <InputGuesser source={"isFetchContent"}/>
          <InputGuesser source={"contentType"}/>

          <ReferenceInput
            allowEmpty={true}
            source="showyAiConfig"
            reference="showy_ai_configs"
            label="Showy Ai Config"
            filterToQuery={searchText => ({name: searchText})}
            // validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
          <ReferenceInput
            allowEmpty={true}
            source="aiAssistant"
            reference="ai_assistants"
            label="AI Assistant"
            filterToQuery={searchText => ({name: searchText})}
            // validate={[required()]}
          >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
        </Tab>
        <Tab label="Parameters" path="parameters">
          <ReferenceManyField
            addLabel={false}
            reference="ai_tool_parameters"
            target="tool"
            sort={{field: 'id', order: 'ASC'}}>
            <Datagrid>
              <TextField source="name"/>
              <FieldGuesser source="type"/>
              <FieldGuesser source="optional"/>
              <FieldGuesser source="description"/>
              <FieldGuesser source="items"/>
              <ShowButton/>
              <EditButton/>
            </Datagrid>
          </ReferenceManyField>
          <AddParameterButton/>
        </Tab>
      </TabbedShowLayout>
    </EditGuesser>
  )
};
